<template>
  <v-autocomplete
    v-model.number="cartBuyer"
    :items="cartBuyerData"
    :search-input.sync="search"
    :name="Math.random()"
    :filter="searchFilter"
    :outlined="outlined"
    item-value="id"
    item-text="name"
    autocomplete="off"
    dense
    hide-details
    class="append-0"
    label="Customer"
  >
    <!-- <template v-slot:append-outer>
      <v-btn color="yellow darken-4" depressed dark class="rounded-xl ma-0">
        <v-icon small>
          mdi-account-plus
        </v-icon>
      </v-btn>
    </template> -->
    <template v-slot:item="data">
      <v-card flat style="background-color: transparent;width: 100%;box-shadow: none!important;">
        <div class="mt-1 font-weight-bold d-flex fill-width justify-space-between align-center" style="font-size: 12px;">
          {{ data.item.name }}
          <small style="font-size: 7px;">
            0{{ data.item.phone }}
          </small>
        </div>
        <div class="text-capitalize d-flex fill-width justify-space-between align-center" style="font-size: 9px;">
          <v-chip x-small :color="data.item.role === 'customer' ? 'teal' : (data.item.role === 'reseller' ? 'orange' : 'indigo')" dark style="font-size: 8px;">
            {{ data.item.role }}
          </v-chip> {{ $GEN_ID(data.item) }}
        </div>
        <v-divider class="mt-2 mb-1" light />
      </v-card>
    </template>
  </v-autocomplete>
</template>

<script>
let searchTimeout = null
export default {
  props: {
    mpl: {
      type: Boolean,
      default: false
    },
    kecamatan: {
      type: String,
      default: ''
    },
    kota: {
      type: String,
      default: ''
    },
    kodepos: {
      type: Number,
      default: 0
    },
    fulladdress: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      default: 0
    },
    outlined: {
      type: Boolean,
      default: false
    },
    buyerData: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    search: '',
    cartBuyer: 0,
    cartBuyerData: [],
    MyAddress: []
  }),
  watch: {
    cartBuyer (v) {
      this.$emit('input', parseInt(v) || 0)
      if (v !== parseInt(this.buyerData.id) && !this.mpl) {
        const f = this.cartBuyerData.find(r => parseInt(r.id) === v)
        this.loadAddress(f)
      }
    },
    search (v) {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      const d = this.cartBuyerData.find(r => r.name === v)
      if (!d && v && this.mpl) {
        searchTimeout = setTimeout(() => {
          this.SEARCH_MEMBER()
        }, 300)
      } else if (!this.mpl) {
        searchTimeout = setTimeout(() => {
          this.SEARCH_MEMBER()
        }, 300)
      }
    }
  },
  mounted () {
    if (this.buyerData) {
      if (this.buyerData.phone) {
        this.cartBuyer = this.buyerData.id
        this.SEARCH_MEMBER(this.buyerData.phone)
      }
    }
  },
  methods: {
    async SEARCH_MEMBER (q) {
      q = q || this.search || ''
      await this.$store.dispatch('MEMBER_GET', ('?limit=20&q=' + q)).then(async (res) => {
        if (res.status) {
          this.cartBuyerData = res.data.data.data || []
          if (this.buyerData.phone && this.cartBuyerData.length === 1) {
            this.cartBuyer = parseInt(this.cartBuyerData[0].id)
          } else if (parseInt(this.buyerData.id) && !this.mpl) {
            const f = this.cartBuyerData.find(r => parseInt(r.id) === parseInt(this.buyerData.id))
            this.loadAddress(f)
          } else if (this.mpl && !this.cartBuyerData.length) {
            this.cartBuyer = 0
            const customerAddress = await this.AreaMplGet()
            const addr = customerAddress.data.length ? Object.assign({}, customerAddress.data[0]) : {}
            const customerData = {
              role: 'customer',
              id: 0,
              name: this.buyerData.name || '',
              phone: this.buyerData.phone || '',
              area: parseInt(addr.area_id) || 0,
              area_name: (addr.kecamatan_name || '#') + ', ' + (addr.type || '#') + ' ' + (addr.city_name || '#') + ', ' + (addr.province || '#'),
              province: parseInt(addr.province_id) || 0,
              city: parseInt(addr.city_id) || 0,
              district: parseInt(addr.kecamatan_id) || 0,
              address: customerAddress.address || ''
            }
            this.cartBuyerData.push(customerData)
            this.$emit('updated-customer', Object.assign({}, customerData))
          }
        }
      })
    },
    async AreaMplGet () {
      const district = (this.kecamatan || '').trim()
      const cityType = (this.kota || '').toUpperCase().match('KAB.') ? 'Kabupaten' : 'Kota'
      const city = (this.kota || '').toUpperCase().replace('KAB.', '').replace('KOTA', '').trim()
      return await this.$store.dispatch('AREA_MPL_GET', {
        type: cityType,
        postal_code: (this.kodepos + '').substring(0, 3),
        q: '?city_name=' + city + '&kecamatan_name=' + district
      })
        .then((res) => {
          if (res && res.status && res.data && res.data.data.length) {
            return {
              data: res.data.data,
              address: this.fulladdress
            }
          }
          return {
            data: [],
            address: this.fulladdress
          }
        })
    },
    loadAddress (updatedBuyer) {
      let user = 0
      if (parseInt(this.cartBuyer)) {
        user = parseInt(this.cartBuyer)
      } else if (this.buyerData && !this.mpl) {
        this.$store.commit('SNACKBAR', { show: true, text: 'No User Selected!' })
        return false
      }
      this.MyAddress = []
      this.$store.dispatch('ADDRESS_GET', user).then((res) => {
        if (res.status) {
          this.MyAddress = res.data.data || []
        } else {
          this.MyAddress = []
        }
        if (updatedBuyer) {
          updatedBuyer.address_data = this.MyAddress || []
          this.$emit('updated', Object.assign({}, updatedBuyer))
        }
      })
    },
    searchFilter (item, queryText, itemText) {
      const d = (itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      return d || this.searchable(item, queryText)
    },
    searchable (item, q) {
      if (q) {
        const ngrx = new RegExp(q, 'gi')
        return ('0' + item.phone.match(ngrx))
      }
      return true
    }
  }
}
</script>
